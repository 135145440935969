<template>
    <div id="download">
        <div id="div1">
            <ul>
                <li v-for="(item, index) in list" @click="handleCreativeTool(item.state, item.url, index)">
                    <div class="top">
                        <div>
                            <img :src="item.img" alt="">
                        </div>
                    </div>
                    <div class="line">
                        <div class="l1"></div>
                        <div class="l2"></div>
                    </div>
                    <div class="bottom">
                        <div>{{ item.name }}</div>
                    </div>
                </li>
            </ul>
        </div>
        <ActivationLesson v-if="selectLessonState" :visible.sync='selectLessonState' :list="unlockedList"
            @handleConfirmGroup="handleConfirmGroup"></ActivationLesson>
        <IpadAlert v-if="alertState" :visible.sync='alertState' :tip="tip" @handleAlertConfirm="alertState = false">
        </IpadAlert>

        <!--        <div class="iframe-box" style="position: fixed;width: 100%;height: 100%;top: 0;left: 0">-->
        <!--            <iframe src="https://service.guocode.com/gbscratch/" width="100%" height="100%" frameborder="0"-->
        <!--                    scrolling="no"></iframe>-->
        <!--        </div>-->

        <!--        <div class="return-btn">-->
        <!--            <svg-icon icon-class="left-point" class="left-point-class"></svg-icon>-->
        <!--        </div>-->
    </div>
</template>

<script>
import { createdLoad } from "@/views/ipad/common";
import UnloadClass from "@/models/Home/UnloadClass";
import ActivationLesson from "@/views/ipad/CreativeTools/components/ActivationLesson";
import IpadAlert from "@/components/ipad/IpadAlert";
import uuid from "@/utils/uuid";

export default {
    name: "index",
    components: {
        ActivationLesson,
        IpadAlert
    },
    data() {
        return {
            iframeJrState: false,
            selectLessonState: false,
            alertState: false,
            tip: '',
            list: [
                // {
                //     name: 'Scratch编程',
                //     img: 'https://storage.shubenji.cn/GuoBao/ipad/tool-1.png',
                //     state: 1,
                //     url:
                //         "/gbscratch/?group_id=" + this.$store.getters.getGroupData.group_id,
                // },
                // {
                //     name: 'Python编程',
                //     img: 'https://storage.shubenji.cn/GuoBao/ipad/tool-2.png',
                //     state: 1,
                //     url: "/pythonide/",
                // },
                // {
                //     name: 'Python3编程',
                //     img: 'https://storage.shubenji.cn/GuoBao/ipad/tool-3.png',
                //     state: 1,
                //     url: "/pygame/",
                // },
                // {
                //     name: '原版Scratch',
                //     img: 'https://storage.shubenji.cn/GuoBao/ipad/tool-4.png',
                //     state: 1,
                //     url: "/guobaoscratch/",
                // },
                {
                    name: '幼儿编程',
                    img: 'https://storage.shubenji.cn/GuoBao/ipad/tool-5.png',
                    state: 1,
                    url: "/scratchJr/",
                }
                , {
                    name: '果动编程',
                    img: 'https://storage.shubenji.cn/GuoBao/ipad/tool-6.png',
                    state: 1,
                    url: "/guobaojr/",
                },
            ],
            unlockedList: [],
            page: 1,
            count: 0,
            loading: false
        }
    },
    created() {
        createdLoad();
        this.initTool(this.page, () => {
            this.page = this.page + 1;
            this.loading = false;
        });
        this.canUnlock();
        // if (this.$store.getters.getUserData.role === 2) {
        //     this.initTool();
        // } else {
        //     this.butState = true;
        // }
    },
    methods: {
        handleIframeReturn() {
            console.log(12)
            this.iframeJrState = false;
        },
        initTool(page, fun = () => { }) {
            //添加缓存 只有学生端的时候
            let _this = this;
            UnloadClass.getListUnlockedLessonNum({ page: page, limit: 8 }, ({ type, list }) => {
                if (type === 200) {
                    _this.$store.commit("setUnloadGroupData", list.list.lesson_num);
                    // this.total = list.count;
                    // this.unlockedList = list.list.unlocked;
                    this.butState = true;

                    if (this.page === list.total) {
                        this.count = list.count;
                    }
                    this.unlockedList = this.unlockedList.concat(list.list.unlocked);
                    if (this.page === 1 && this.unlockedList.length === 0) {
                        // this.notInfoState = true;
                    }
                    fun();
                }
            });
        },
        loadworks() {
            console.log(this.loading)
            if (this.loading == true) return
            this.loading = true;
            this.initTool(this.page, () => {
                this.page = this.page + 1;
                this.loading = false;
            })
        },
        canUnlock() {
            UnloadClass.canUnlock({}, ({ type, list }) => {
                if (type === 200) {
                    if (list !== 0) {
                        this.$store.commit('setCanUnlock', list)
                    } else {
                        this.$store.commit('setCanUnlock', list)
                    }
                }
            })
        },
        handleCreativeTool(state, url, key) {
            let status = false;
            if (this.$store.getters.getUserData.role === 2) {
                if ((
                    localStorage.getItem("unloadGroupData") !== null &&
                    localStorage.getItem("unloadGroupData") > 0
                ) || (localStorage.getItem("canUnlock") !== undefined && localStorage.getItem("canUnlock") > 0)) {
                    status = true;
                } else {
                    if (this.unlockedList.length === 0) {
                        this.tip = '请先让老师解锁课程';
                        this.alertState = true;
                    } else {
                        this.selectLessonState = !this.selectLessonState;
                        console.log(this.selectLessonState);
                    }
                }
            }

            if (this.$store.getters.getUserData.role === 1) {
                status = true;
            }
            if (status === true) {
                // this.iframeJrState = true;
                // window.open(url);
                if (url === '/guobaojr/') {
                    this.$router.push({ path: '/scratchJr', query: { url: `${this.$cdnUrl}/guobaojr/` } })
                } else {
                    this.$router.push({ path: '/scratchJr', query: { url: `${this.$cdnUrl}/jr/` } })
                }
            }
        },
        handleConfirmGroup(item) {
            if (item != null) {
                this.unlockedAlert = false;
                this.$router.push({
                    path: "/ipad/lessonDetail",
                    query: {
                        lesson_id: item.lesson_id,
                        lesson_name: item.lesson_name,
                        group_id: this.$store.getters.getGroupData.group_id,
                        unlock_group_id: item.group_id,
                        unlock: 'unlock',
                        course_id: item.course_id,
                        text: item.lesson_text
                    },
                });
            } else {
                this.alertState = true;
                this.tip = '请选择要激活的课时';
            }
        }
    }
}
</script>

<style lang="less" scoped>
@import "./creative-tools.less";
</style>
