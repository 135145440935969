<template>
    <div class="works-class">
        <div class="works-screen-class">
            <div>
                <ul>
                    <li v-for="(item,index) in searchTypeData" :key="index">
                        <div class="search-type-class" :class="{'select-but-class' : selectStyle === index}"
                             @click="searchButClick(index)">
                            <svg-icon :icon-class="item.svgIcon" :class="{'svgIconColor':selectStyle === index}"
                                      class="svg-icon-class"/>
                            <div class="type-name-class">
                                <span v-if="item.id === 0">&nbsp;&nbsp;&nbsp;&nbsp;{{ item.name }}</span>
                                <span v-else>{{ item.name }}</span>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
            <div class="cut-pattern-class">
                <div class="pattern1-class" @click="sortButClick">按时间排序
                    <svg-icon :class="{'svg-icon-active':sortButState}" icon-class="point-icon" class="svg-icon-class"/>
                </div>
                <div class="pattern2-class" ref="treeWrap" v-if="sortButState"
                     @click="$router.push({path:'worksGather'})">按作品集合排序
                </div>
            </div>
        </div>
        <div class="periphery-class" id="periphery-class">
            <div class="works-content-class">
                <van-list
                    v-model="loading"
                    :finished="finished"
                    :offset="offset"
                    @load="onLoad"
                >
                    <div>
                        <div class="works-detail-class" v-for="(item,index) in info" :key="index">
                            <div class="main-box-class" v-if="showButState && index < 8" @click="hrefClick(item)">
                                <div :class="{'z-index-class':commentButState === index}"></div>
                                <div class="con-class">
                                    <span v-if="item.work_image_url === '' || item.work_image_url === '0'">
                                        <el-image v-if="item.class_name === '课后作业'"
                                                  class="work-back-img" :src="worksImg1"
                                                  fit="cover">
                                        </el-image>
                                        <el-image v-if="item.class_name === '课堂练习'"
                                                  class="work-back-img" :src="worksImg2"
                                                  fit="cover">
                                        </el-image>
                                        <el-image v-if="item.class_name === '自由编程'"
                                                  class="work-back-img" :src="worksImg3"
                                                  fit="cover">
                                        </el-image>
                                    </span>
                                    <span v-else>
                                        <el-image class="work-back-img" :src="item.work_image_url"
                                                  fit="cover">
                                        </el-image>
                                    </span>
                                </div>
                                <div class="detail-class">
                                    <div class="title-name-class">
                                        <div class="name-class">
                                            <span class="headline-class">
                                                {{ item.work_name }}
                                            </span>
                                            <!--                                            <span class="hot-icon-class">-->
                                            <!--                                                <el-image class="hot-icon" :src="hotIcon"-->
                                            <!--                                                          fit="cover">-->
                                            <!--                                                </el-image>-->
                                            <!--                                            </span>-->
                                        </div>
                                    </div>
                                    <div class="tags-class">
                                        <span>{{ item.class_name }}</span>
                                        <span>{{ item.type_name }}</span>
                                    </div>
                                    <div class="praise-class">
                                        <div class="lesson-name-class">{{ item.lesson_name }}</div>
                                        <div class="comment-box-class" :class="{'but-click-class':commentButState === index}">
                                            <div class="comment-but-class"
                                                 @click.stop="commentButClick(item.work_id,index,item.teacher_comment)">
                                                <div class="hover-style-class">
                                                    <svg-icon icon-class="comment-icon" class="svg-icon-class"/>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="share-box-class" :class="{'but-click-class':shareButState === index}">
                                            <div class="share-but-class"
                                                 @click.stop="shareButClick(index,item.work_id)">
                                                <svg-icon icon-class="share-icon" class="svg-icon-class"/>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="user-class">
                                        <div>
                                            <div class="user-box-class">

                                                <el-image v-if="item.avatar_url === ''" class="head-class"
                                                          :src="headImg"
                                                          fit="cover">
                                                </el-image>
                                                <el-image v-else class="head-class" :src="item.avatar_url"
                                                          fit="cover">
                                                </el-image>
                                            </div>
                                            <div class="user-box-class nickname-class">

                                                <el-tooltip class="item" effect="dark" :content="item.nickname" placement="bottom">
                                                   <span>{{ item.nickname }}</span>
                                                </el-tooltip>
                                            </div>
                                            <div class="time-box-class">{{
                                                    Number(item.issue_time) * 1000 | formatDate3
                                                }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="main-box-class" v-else-if="showButState === false" @click="hrefClick(item)">
                                <div :class="{'z-index-class':commentButState === index}"></div>
                                <div class="con-class">
                                    <span v-if="item.work_image_url === '' || item.work_image_url === '0'">
                                        <el-image v-if="item.class_name === '课后作业'"
                                                  class="work-back-img" :src="worksImg1"
                                                  fit="cover">
                                        </el-image>
                                        <el-image v-if="item.class_name === '课堂练习'"
                                                  class="work-back-img" :src="worksImg2"
                                                  fit="cover">
                                        </el-image>
                                        <el-image v-if="item.class_name === '自由编程'"
                                                  class="work-back-img" :src="worksImg3"
                                                  fit="cover">
                                        </el-image>
                                    </span>
                                    <span v-else>
                                        <el-image class="work-back-img" :src="item.work_image_url"
                                                  fit="cover">
                                        </el-image>
                                    </span>
                                </div>
                                <div class="detail-class">
                                    <div class="title-name-class">
                                        <div class="name-class">
                                        <span class="headline-class">
                                            {{ item.work_name }}
                                        </span>
                                            <!--                                            <span class="hot-icon-class">-->
                                            <!--                                                <el-image class="hot-icon" :src="hotIcon"-->
                                            <!--                                                          fit="cover">-->
                                            <!--                                                </el-image>-->
                                            <!--                                            </span>-->
                                        </div>
                                    </div>
                                    <div class="tags-class">
                                        <span>{{ item.class_name }}</span>
                                        <span>{{ item.type_name }}</span>
                                    </div>
                                    <div class="praise-class">
                                        <div class="lesson-name-class">{{ item.lesson_name }}</div>
                                        <div class="comment-box-class" :class="{'but-click-class':commentButState === index}">
                                            <div class="comment-but-class"
                                                 @click.stop="commentButClick(item.work_id,index,item.teacher_comment)">
                                                <div class="hover-style-class">
                                                    <svg-icon icon-class="comment-icon" class="svg-icon-class"/>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="share-box-class" :class="{'but-click-class':shareButState === index}">
                                            <div class="share-but-class"
                                                 @click.stop="shareButClick(index,item.work_id)">
                                                <svg-icon icon-class="share-icon" class="svg-icon-class"/>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="user-class">
                                        <div>
                                            <div class="user-box-class">

                                                <el-image v-if="item.avatar_url === ''" class="head-class"
                                                          :src="headImg"
                                                          fit="cover">
                                                </el-image>
                                                <el-image v-else class="head-class" :src="item.avatar_url"
                                                          fit="cover">
                                                </el-image>
                                            </div>
                                            <div class="user-box-class nickname-class">
                                                <el-tooltip class="item" effect="dark" :content="item.nickname" placement="bottom">
                                                   <span>{{ item.nickname }}</span>
                                                </el-tooltip>
                                            </div>
                                            <div class="time-box-class">{{
                                                    Number(item.issue_time) * 1000 | formatDate3
                                                }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </van-list>
                <div class="show-all-class" v-if="count > 8 && showButState">
                    <div class="show-but-class" @click="showButClick()">展开全部 <i class="el-icon-arrow-down"></i></div>
                </div>
            </div>
        </div>
        <div class="return-top-class">
            <div class="icon-box-class" @click="returnButClick">
                <div class="icon-back-class">

                </div>
                <div class="icon-text-class">回顶部</div>
            </div>
        </div>

        <!--评论弹窗-->
<!--        <CommentAlert class="comment-alert-class" v-if="commentState" :commentData="commentData"-->
<!--                      @closeCommentAlert="closeCommentAlert"></CommentAlert>-->

        <CommentAlertV class="comment-alert-class" v-if="commentState" :commentData="commentData"
                      @closeCommentAlert="closeCommentAlert"></CommentAlertV>

        <!-- <ShareAlert v-if="shareState" :shareData="shareData" @closeShareAlert="closeShareAlert"></ShareAlert> -->
        <SharePopPc v-if="shareState" :shareData="shareData" @closeShareAlert="closeShareAlert"></SharePopPc>
    </div>
</template>

<script>
import Works from "../../models/Home/Works";
import {formatDate} from "../../assets/js/date.js";
import CommentAlert from "../../components/comment/CommentAlert";
import CommentAlertV from "../../components/comment/CommentAlertV";
import ShareAlert from "../../components/share/ShareAlert";
import uuid from "@/utils/uuid";
import SharePopPc from "../../components/share/SharePopPc.vue";

export default {
    name: "StudentWorks",
    components: {CommentAlert, ShareAlert, CommentAlertV,SharePopPc},
    data() {
        return {
            gatherState: false,
            worksImg1: './img/works-1.png',
            worksImg2: './img/works-2.png',
            worksImg3: './img/works-3.png',
            worksContHeight: '680',
            selectStyle: 0,
            hotIcon: './img/hot-icon.png',
            headImg: './img/default-head-img2.png',
            workImg: './img/works-3.png',
            returnTopImg: './img/icon/return-top-icon.png',
            searchTypeData: [
                {
                    name: '全部',
                    state: true,
                    icon: './img/search-all.png',
                    cutIcon: './img/search-cut-all.png',
                    svgIcon: 'works-all-icon',
                    id: 0
                }, {
                    name: '课堂练习',
                    state: false,
                    icon: './img/search-study.png',
                    cutIcon: './img/search-cut-study.png',
                    svgIcon: 'study-icon',
                    id: 1
                }, {
                    name: '课后作业',
                    state: false,
                    icon: './img/search-work.png',
                    cutIcon: './img/search-cut-work.png',
                    svgIcon: 'class-work-icon',
                    id: 2
                }, {
                    name: '自由编程',
                    state: false,
                    icon: './img/search-pro.png',
                    cutIcon: './img/search-cut-pro.png',
                    svgIcon: 'pro-icon',
                    id: 3
                },
            ],
            page: 1,
            limit: 4,
            class_id: 0,
            list: [],
            info: [],
            count: 0,
            showButState: true,
            offset: 10,
            loading: false,
            finished: false,
            commentData: {
                work_id: 0,
                content: ""
            },
            commentState: false,
            commentButState: false,
            shareButState: false,
            shareState: false,
            shareData: {},
            sortButState: false,
        }
    },
    created() {
        let _this = this;
        document.addEventListener('mouseup', (e) => {
            let tree = this.$refs.treeWrap
            if (tree) {
                console.log(tree);
                if (tree !== undefined) {
                    if (!tree.contains(e.target)) {
                        // this.isShowTree =false
                        this.gatherState = false;
                        this.sortButState = false;
                    }
                }
            }
        })
        this.getWorksShow();
    },
    computed: {
        CourseData() {//监听store的数据
            return this.$store.getters.getCourseData
        }
    },
    watch: {
        CourseData() {
            this.page = 1;
            this.showButState = true;
            this.finished = false;
            this.loading = true;
            this.getWorksShow();
        }
    },
    methods: {
        hrefClick(item) {
            if (item.type === 'sb3') {
                window.open("/gbscratch/?workId=" + item.work_id);
            }
            if (item.type === 'python') {
                window.open("/pygame/?workId=" + item.work_id);
            }
            if (item.type === 'sjr') {
                window.open(`/jr/?&workId=${item.work_id}`);
            }
            if (item.type === 'guodongsjr') {
                window.open(`/guobaojr/?&workId=${item.work_id}&aa=1`);
            }
        },
        //切换排序
        sortButClick() {
            this.sortButState = this.sortButState === false;
        },
        onLoad() {
            //上拉加载
            if (this.showButState === false) {
                this.page++;
                this.getWorksShow();
            }
        },
        searchButClick(index) {
            this.selectStyle = index;
            this.class_id = this.searchTypeData[index].id;
            this.page = 1;
            this.finished = false;
            this.loading = true;
            if (this.count > 8) {
                this.showButState = true;
            }
            this.getWorksShow();
        },
        getWorksShow() {
            let param =
                {
                    class_id: this.class_id,
                    group_id: this.$store.getters.getGroupData.group_id,
                    page: this.page,
                    limit: 8,
                }
            Works.getWorksShow(param, ({type, list}) => {
                if (type === 200) {
                    this.count = list.count;
                    // this.list = list.list;
                    list.list.forEach((item, index) => {
                        if (item.type === 'sb3') {
                            item.type_name = 'scratch';
                        } else if (item.type === 'python') {
                            item.type_name = 'python';
                        }else if (item.type === 'sjr') {
                            item.type_name = 'scratchJr';
                        }else if (item.type === 'guodongsjr') {
                            item.type_name = '果动';
                        }
                    });
                    if (this.page == 1) {
                        this.refreshing = false;
                        this.list = [];
                        list.list.forEach((element) => {
                            this.list.push(element);
                        });
                    } else {
                        list.list.forEach((element) => {
                            this.list.push(element);
                        });
                    }

                    this.info = this.list;
                    if (list.list.length < 8) {
                        this.finished = true;
                    }
                    this.loading = false;
                }
            })
        },
        showButClick() {
            this.page++;
            this.showButState = false;
            this.getWorksShow(3)
        },
        returnButClick() {
            let arr = [];
            this.info.forEach((item, index) => {
                if (index < 8) {
                    arr.push(item);
                }
            })
            console.log(arr)
            this.info = arr;
            this.showButState = true;
            let top = document.getElementById('periphery-class').scrollTop;
            let self = this;
            // 实现滚动效果
            const timeTop = setInterval(() => {
                document.getElementById('periphery-class').scrollTop = top -= 50;
                if (top <= 0) {
                    clearInterval(timeTop);
                }
            }, 10);
        },
        //评价按钮
        commentButClick(work_id, index, teacher_comment) {
            console.log(teacher_comment)
            this.commentButState = index;
            this.commentState = true;
            this.commentData.work_id = work_id;
            this.commentData.content = teacher_comment;
        },
        //分享按钮
        shareButClick(index, work_id) {
            this.shareButState = index;
            this.shareState = true;

            const param = {
                work_id: work_id,
            }
            Works.getWorksQrCode(param, ({type, list}) => {
                if (type === 200) {
                    this.shareData = list;
                }
            })
        },
        closeCommentAlert() {
            this.commentState = false;
            this.commentButState = false;
        },
        closeShareAlert() {
            this.shareState = false;
            this.shareButState = false;
        },
        saveInfo(commentData) {
            this.info.forEach((item, index) => {
                if (item.work_id === commentData.work_id) {
                    item.teacher_comment = commentData.content
                }
            })
        }
    },
    filters: {
        /*
    时间格式自定义 只需把字符串里面的改成自己所需的格式
   */
        formatDate(time) {
            var date = new Date(time);
            return formatDate(date, "yyyy.MM.dd");
        },
        formatDate2(time) {
            var date = new Date(time);
            return formatDate(date, "hh:mm:ss");
        },
        formatDate3(time) {
            var date = new Date(time);
            return formatDate(date, "yyyy.MM.dd. hh:mm");
        },
    },
}
</script>

<style lang="less" scoped>
@import "./StudentWorks";
</style>
